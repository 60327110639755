import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import PortableText from "../components/Blog/portableText";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import CityLoanPrograms from "../components/Repeating/CityLoanPrograms";
import Resources from "../components/Repeating/Resources";
import About from "../components/Repeating/About";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import HomeWorth from "../components/Repeating/HomeWorth";

//Hero scroll dependencies
import decorativeLine from "../images/1.0 Homepage/1.0 Hero path.svg";
import blueDecorative from "../images/1.0 Homepage/blue-decorative.svg";
import heroDecorative from "../images/1.0 Homepage/1.0 Hero ornament.svg";
import ornament from "../images/1.0 Homepage/Ornament.svg";
import scrollDown from "../images/1.0 Homepage/scroll down.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const query = graphql`
  query CityTemplateQuery($id: String!) {
    city: sanityCities(id: { eq: $id }) {
      id
      title
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      state
      heroDesktop {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      heroMobile {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      whyWeLoveImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
      _rawWhyWeLoveText
    }
  }
`;

const Template = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.city.seoTitle}
        description={data.city.metaDescription}
        openGraphImage={
          data.city.openGraphImage && data.city.openGraphImage.asset.url
        }
        twitterOpenGraphImage={
          data.city.twitterCardImage && data.city.twitterCardImage.asset.url
        }
      />
      {/* START OF HERO */}
      <section className="mb-32 md:mb-56 md:pt-28">
        <div className="container relative">
          <img
            src={decorativeLine}
            className="absolute right-44 -top-28 hidden md:block"
          />

          <div className="grid md:grid-cols-2 md:gap-x-14 lg:gap-x-28">
            <div className="order-2 md:order-1">
              <div className="mb-2.5 font-heading text-mobile-6xl text-typography-heading md:mb-6 md:text-7xl">
                Your path to a new home starts here.
              </div>
              <p className="text-mobile-3xl mb-7 font-display font-light tracking-wide text-secondary-600 md:text-4xl">
                The best loan programs. Superior customer experience.
              </p>
              <div className="flex items-center space-x-4">
                <ButtonSolid
                  href="/conventional-home-loans/"
                  text="Purchase"
                  className="w-full min-w-0 md:w-auto md:min-w-[180px]"
                />
                <ButtonSolid
                  href="/refinance-pasadena/"
                  text="Refinance"
                  altStyle={2}
                  className="w-full min-w-0 md:w-auto md:min-w-[180px]"
                />
              </div>
            </div>
            <div className="relative order-1 md:order-2">
              <div className="hidden md:block">
                <ParallaxProvider>
                  <Parallax speed={8}>
                    <StaticImage
                      src="../images/1.0 Homepage/1.0 Hero Image01.png"
                      loading="eager"
                      width={447}
                    />
                  </Parallax>
                  <div className="absolute -bottom-36 right-0">
                    <Parallax speed={3}>
                      <StaticImage
                        src="../images/1.0 Homepage/Hero Image02.png"
                        loading="eager"
                        width={214}
                      />
                    </Parallax>
                  </div>
                </ParallaxProvider>

                <img
                  src={heroDecorative}
                  className="absolute -right-7 -bottom-40 z-[-10]"
                />
              </div>
              <div className="md:hidden">
                <StaticImage
                  src="../images/1.0 Homepage/1.0 Hero Image.png"
                  loading="eager"
                  width={344}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative mb-24 hidden md:block">
        <div className="absolute left-10 -top-44">
          <ParallaxProvider>
            <Parallax speed={6}>
              <StaticImage
                src="../images/1.0 Homepage/Hero Image03.png"
                loading="lazy"
                width={207}
              />
            </Parallax>
          </ParallaxProvider>
          <img
            src={blueDecorative}
            className="absolute -right-7 top-6 z-[-10]"
          />
        </div>

        <div className="container">
          <AnchorLink stripHash offset={-20} to="#CityIntro">
            <img
              src={scrollDown}
              alt="Scroll arrow"
              width="26px"
              className="bounce mx-auto"
            />
          </AnchorLink>
        </div>
      </section>
      {/* END OF HERO */}
      {/* START OF INTRO */}
      <section id="CityIntro" className="mb-18 md:mb-24">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="relative">
              <img
                src={ornament}
                className="absolute -right-16 -top-14 -z-[9999] md:-top-32 md:right-40"
              />
              <h1>
                Your Mortgage Broker for Home Loans in {data.city.title} &
                Beyond
              </h1>
              <p className="mb-0">
                Accel Mortgage is a full-service Pasadena mortgage broker
                serving Los Angeles County and all of California. We cater to
                your individual needs and long-term financial goals. We’ll help
                you secure a great loan to buy your dream home, refinance the
                one you own, walk you through a reverse mortgage, or invest in
                property.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 collage.png"
                loading="lazy"
                width={553}
              />
            </div>
          </div>
        </div>
      </section>
      {/* END OF INTRO */}
      <CityLoanPrograms location={data.city.title} />
      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mx-auto mb-10 max-w-[720px] md:mb-14 md:text-center">
            <h2>I Want To Refinance My Home</h2>
            <p>
              Lower your monthly payment, cash out equity, get a better interest
              rate, change the terms—refinancing sure has a lot of benefits.
            </p>
          </header>

          <div className="-mx-4 grid gap-y-6 md:mx-0 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <Link
                to="/refinance-pasadena/"
                className="group block h-full overflow-hidden rounded-6xl font-normal text-typography-body no-underline shadow-5xl hover:text-typography-body hover:shadow-6xl md:rounded-4xl"
              >
                <StaticImage
                  src="../images/1.0 Homepage/refinance.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full"
                />
                <div className="h-full bg-primary-100 px-6 py-7 transition-colors duration-300 ease-linear group-hover:bg-primary-900 md:p-8">
                  <h3 className="heading-four text-typography-heading transition-colors duration-300 ease-linear group-hover:text-white">
                    Refinance
                  </h3>
                  <p className="mb-0 text-sm text-typography-heading transition-colors duration-300 ease-linear group-hover:text-white md:text-base">
                    Need wiggle room in your budget? Want to upgrade your home?
                    Refinancing saves you money. What you do with the cash is up
                    to you.
                  </p>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/reverse-mortgage-pasadena/"
                className="group block h-full overflow-hidden rounded-6xl font-normal text-typography-body no-underline shadow-5xl hover:text-typography-body hover:shadow-6xl md:rounded-4xl"
              >
                <StaticImage
                  src="../images/1.0 Homepage/5.0 Reverse Mortgage.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full"
                />
                <div className="h-full bg-primary-100 px-6 py-7 transition-colors duration-300 ease-linear group-hover:bg-primary-900 md:p-8">
                  <h3 className="heading-four text-typography-heading transition-colors duration-300 ease-linear group-hover:text-white">
                    Reverse Mortgage
                  </h3>
                  <p className="mb-0 text-sm text-typography-heading transition-colors duration-300 ease-linear group-hover:text-white md:text-base">
                    Are you a homeowner who is 62 or older? Get tax-free income
                    by borrowing against your home’s equity.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <HomeWorth />
      <Resources />
      <Testimonials />
      <ValueProps />
      <About />
      <RecentBlogPosts className="mb-20 md:mb-32" />
      {data.city._rawWhyWeLoveText && data.city.whyWeLoveImage && (
        <section className="mb-20 md:mb-32">
          <div className="container">
            <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div>
                <GatsbyImage
                  image={data.city.whyWeLoveImage.asset.gatsbyImageData}
                  className="z-0 mb-10 rounded-3xl"
                />
              </div>
              <div>
                <PortableText blocks={data.city._rawWhyWeLoveText} />
              </div>
            </div>
          </div>
        </section>
      )}
      <CallToAction />
    </Layout>
  );
};

export default Template;
